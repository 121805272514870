@import url(https://fonts.googleapis.com/css?family=Josefin+Sans);
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  /* margin-top: 20px; */
  
}

/* .signup{
  text-decoration: none;
   
} */
/* .form{
  box-shadow: 0 3px 5px 2px rgba(255, 105, 135, .3);
} */

.wrapper {
  display: flex;
  flex-direction: row;
}

.left {
  display: flex;
  flex-direction: column;
  flex: 1 1;
  align-items: center;
  justify-content: center;
  height: 80vh;
}

.right {
  display: flex;
  flex: 1 1;
  align-items: center;
  justify-content: center;
  height: 80vh;
}

#img-pane {
  display: flex;
  justify-content: center;
  align-items: center;
  /* height: 100vh; */
}

.login {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-right: 10px;
  align-items: center;
  
}

@media only screen and (min-width: 1024px) {
  .left {
    flex: 2 1;
  }

  .right {
    flex: 6 1;
  }
}

@media only screen and (max-width: 1024px) {
  .wrapper {
    flex-direction: column;
    /* padding: 3rem; */
    justify-content: center;
  }
  .left{
    flex:1 1
  }
  .right{
    flex:1 1
  }
  .logo{
    max-width: 300px;
  }
}

body {
  margin-top: 20px;
  padding-top: 55px;
  padding-right: 10px;
  padding-left: 10px;
}

h2 {
  font-family: "Josefin Sans", cursive;
}

.container{
    margin-left: 16rem;
    margin-top: -2rem;
}
.container .createbutton{
    background-color: #0077b6;
    color: #fff;
    cursor: pointer;
    
}
.section .DialogTitle{
   
}
.Loader_Loader__2CHFV,
.Loader_Loader__2CHFV:before,
.Loader_Loader__2CHFV:after {
  border-radius: 50%;
  width: 2.5em;
  height: 2.5em;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation: Loader_load7__3LMkZ 1.8s infinite ease-in-out;
  animation: Loader_load7__3LMkZ 1.8s infinite ease-in-out;
}
.Loader_Loader__2CHFV {
  color: #2145e6;
  font-size: 10px;
  margin: 80px auto;
  position: relative;
  text-indent: -9999em;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}
.Loader_Loader__2CHFV:before,
.Loader_Loader__2CHFV:after {
  content: "";
  position: absolute;
  top: 0;
}
.Loader_Loader__2CHFV:before {
  left: -3.5em;
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}
.Loader_Loader__2CHFV:after {
  left: 3.5em;
}
@-webkit-keyframes Loader_load7__3LMkZ {
  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }
  40% {
    box-shadow: 0 2.5em 0 0;
  }
}
@keyframes Loader_load7__3LMkZ {
  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }
  40% {
    box-shadow: 0 2.5em 0 0;
  }
}

